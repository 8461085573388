//get 和 post 请求重写
import request from './request'

// get请求
export function get(url: string) {
    return function (params:object): Promise<any> {
        return request.get(url, {params})
            .then(res => {
                return res
            })
            .catch(err => {
                return Promise.reject(err)
            })
    }
}

//post请求
export function post(url: string) {
    return function (data:object): Promise<any> { //opinos用来中断请求
        return request.post(url, data)
            .then(res => {
                return res
            })
            .catch(err => {
                return Promise.reject(err)
            })
    }
}